<template>
  <section id="banner">
    <div class="container overflow-hidden ">
      <div class="
                  col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
                  pb-2
                  ps-0
                  pe-0
                  mx-auto
                ">
          <div class=" partnership-banner-items-container">
            <div class="row partnership-main-banner-item">
              <div class="
                        col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5
                      ">
                <p class="h2 partnership-banner-item-text">
                  <span class="text-blue">Tərəfdaşlara</span>
                  Təkliflərimiz
                </p>
                <p class="partnership-banner-item-content text-gray">
                  Biz daima sizin rahatlığınızı düşünərək, işinizin inkişafı üçün unikal xidmətlər təklif edirik!
                </p>
                <a  class="
                          btn-main
                          btn-default
                          text-decoration-none
                          btn-hover
                          d-inline-block
                          font-size-default
                          mt-2
                          cursor-pointer
                        " @click.prevent="goToConnect">
                  İndi başla
                </a>
              </div>
              <div class="
                        col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 contact_partner_img
                      ">
                <img src="@/assets/images/partner_bg.png" class="partner_bg" />
              </div>
            </div>
          </div>
        </div>
    </div>
  </section>

  <section id="contact-details" class="partnership-contact-detailes">
    <div class="container overflow-hidden">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 px-0">
        <p class="h3 text-center text-main fw-bold pb-4">
          Bizimlə
          <span class="text-blue">Əməkdaşlıq</span>
        </p>
        <div class="container">
          <div class="row contact-details-row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 px-0 partner-detail-item">
            <div class="
                      contact-detail-item-body
                      d-flex
                      align-content-center
                      flex-column
                    ">
              <div class="text-decoration-none d-inline-block w-100">
                <img src="@/assets/images/corporativ.svg" />
                <p class="h6 partner-item-head mt-3">Korporativ Müştərilər</p>
                <p class="partner-item-content">
                  Qoşulun və bir sıra şirkətlərin məhsullarının satışı üçün sadə,
                  sürətli və effektiv bir həll əldə edin.
                </p>
              </div>
              <router-link to="/partnership/corporate" class="
                  btn btn-main btn-default btn-hover cursor-pointer  partner-link">
                Ətraflı
                <img src="@/assets/images/partnerArrow.svg" class="blue_arrow" />
              </router-link>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 px-0 partner-detail-item">
            <div class="
                      contact-detail-item-body
                      d-flex
                      align-content-center
                      flex-column
                    ">
              <div class="text-decoration-none d-inline-block w-100">
                <img src="@/assets/images/sell.svg" />
                <p class="h6 partner-item-head mt-3">Satış və xidmət mərkəzləri</p>
                <p class="partner-item-content">
                  Sığorta şirkəti ilə IT platformasına giriş əldə edin,
                  müştərilərinizə təklif olunan xidmətləri genişləndirin.
                </p>
              </div>
              <router-link to="/partnership/other" class="
                    btn btn-main btn-default btn-hover cursor-pointer  partner-link
                      ">
                Ətraflı
                <img src="@/assets/images/partnerArrow.svg" width="18" class="blue_arrow" />
              </router-link>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 px-0 partner-detail-item">
            <div class="
                      contact-detail-item-body
                      d-flex
                      align-content-center
                      flex-column
                    ">
              <div class="text-decoration-none d-inline-block w-100">
                <img src="@/assets/images/agents.svg" />
                <p class="h6 partner-item-head mt-3">
                  Agentlər
                </p>
                <p class="partner-item-content">
                  Qoşulun və bir sıra şirkətlərin məhsulların satışı üçün sadə,
                  sürətli və effektiv bir həll əldə edin.
                </p>
              </div>
              <router-link to="/partnership/agent" class="
                        btn btn-main  btn-default btn-hover cursor-pointer  partner-link">
                Ətraflı
                <img src="@/assets/images/partnerArrow.svg" width="18" class="blue_arrow" />
              </router-link>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  methods: {
    goToConnect() {
      document.getElementById("contact-details").scrollIntoView();
    },
  },
};
</script>